/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Box, { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import RatgeberList from './Ratgeber/RatgeberList';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';

export default function GuidesPlugin({ ratgeberItems }) {
  return (
    <Section background="white">
      <div sx={{ marginBottom: 7 }}>
        <Heading>Expertentipps</Heading>
        <Subheading>Bleiben Sie informiert!</Subheading>
      </div>
      <RatgeberList ratgeberItems={ratgeberItems} />
      <div sx={{ display: 'flex', marginTop: 7 }}>
        <Button sx={{ marginX: 'auto' }} variant="onDark" to="/expertentipps/">
          Alle Expertentipps <i class="fal fa-arrow-right"></i>
        </Button>
      </div>
    </Section>
  );
}
