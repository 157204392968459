import { ListingsList } from '../ListingsList/index';
import { ListingCard as ListingCardStyles } from '../ListingsList/ListingCard/ListingCard.5.styles';
import React from 'react';
import RatgeberItem from './RatgeberItem';

const RatgeberList = ({ ratgeberItems, image }) => {
  return (
    <ListingsList>
      {ratgeberItems &&
        ratgeberItems.map(ratgeberItem => (
          <RatgeberItem
            sx={ListingCardStyles}
            title={ratgeberItem.name}
            subtitle={ratgeberItem.subtitle}
            description={ratgeberItem.description}
            featuredImage={ratgeberItem.image}
            alternativeImage={ratgeberItem.alternativeImage}
            slug={ratgeberItem.slug}
            leftButton={ratgeberItem.leftButton}
            rightButton={ratgeberItem.rightButton}
            rightSlug={ratgeberItem.rightSlug}
          />
        ))}
    </ListingsList>
  );
};

export default RatgeberList;
