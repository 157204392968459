/** @jsx jsx */
import { jsx } from 'theme-ui';
import CardDeck from 'react-bootstrap/CardDeck';
import { ListingsCardDeck as styles } from './ListingsList.styles';

export default function ListingsList(props) {
  return (
    <CardDeck
      sx={{ ...styles, variant: 'layout.ListingsList.ListingsCardDeck' }}
      {...props}
    />
  );
}
