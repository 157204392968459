import React from 'react';
import ListingsCardDeck from './ListingsCardDeck';

export default function ListingsList({ variant, ...rest }) {
  // Return the ListingsList variants here.
  switch (variant) {
    case 'card':
    default:
      return <ListingsCardDeck {...rest} />;
  }
}
