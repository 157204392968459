/** @jsx jsx */
import { Container } from '@fify.at/gatsby-theme-base/src/components/Grid';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Layout from '@fify.at/gatsby-theme-base/src/components/Layout/Layout';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import { useEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import GuidesPlugin from '../components/GuidesPlugin';
import { graphql } from 'gatsby';

const RatgeberTemplate = ({
  data,
  location,
  pageContext: { url, title, metaDescription, keywords, image },
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener('message', function(event) {
      if (event.data.hasOwnProperty('FrameHeight')) {
        document.getElementById('IframeId').style.height =
          event.data.FrameHeight + 'px';
      }
    });
  }

  function setIframeHeight(ifrm) {
    let height = ifrm.target.contentWindow.postMessage('FrameHeight', '*');
  }

  const ratgeberList = data.site.siteMetadata.ratgeberList.slice(0, 2);

  return (
    <Layout
      location={location}
      title={title}
      image={image.childImageSharp.fluid}
      description={metaDescription}
      keywords={keywords}
      variant="styles.Ratgeber"
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title={title}
        image={image}
      />

      <Section container="small">
        <Container>
          {mounted && <div dangerouslySetInnerHTML={{ __html: url }} />}
        </Container>
      </Section>
      <Section>
        <GuidesPlugin ratgeberItems={ratgeberList} />
      </Section>
    </Layout>
  );
};

export default RatgeberTemplate;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        ratgeberList {
          slug
          url
          name
          image {
            childImageSharp {
              fluid(maxWidth: 700, fit: COVER, quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          description
        }
      }
    }
  }
`;
