export const ListingCard = {
  // Style the card as flex components within a CardDeck.
  // We have to override the bootstrap styles by increasing specificity.
  '&&': {
    // The x margins must be removed.
    flexBasis: [
      'calc(100% - 1rem)',
      'calc(100% - 1rem)',
      'calc(50% - 1rem)',
      // 'calc(33.3333333% - 1rem)',
    ],
    maxWidth: [
      'calc(100% - 1rem)',
      'calc(100% - 1rem)',
      'calc(50% - 1rem)',
      // 'calc(33.3333333% - 1rem)',
    ],
    margin: 2,
    borderRadius: 0,
    border: 0,
    backgroundColor: 'transparent',
  },

  ':hover': { textDecoration: 'none' },
  '& picture > img': { transition: 'all 0.3s ease-out !important' },
  ':hover picture > img': { transform: 'scale(1.1)' },
};

export const ListingCardImage = {
  div: {
    paddingBottom: [
      '102% !important',
      '66% !important',
      '102% !important',
      '66% !important',
    ],
  },
  borderRadius: 0,
  img: {
    objectPosition: 'left bottom !important',
    bottom: '0 !important',
    top: 'auto !important',
  },
};

export const ListingCardFooter = {
  '&&': {
    borderRadius: 0,
  },
};

export const ListingCardInfos = {
  color: 'text',
  display: 'flex',
  flexFlow: ['column', null, 'row', null],
  alignItems: ['flex-start', null, 'flex-end', null],
  justifyContent: 'space-between',
  paddingTop: 0,
  marginTop: 3,
};

export const ListingCardBody = {
  marginLeft: '20px',
  marginRight: '20px',
  marginTop: '-30px',
  zIndex: 1,
  backgroundColor: '#fff',
  boxShadow: `1px 2px 7px rgba(0,0,0,0.2)`,
  display: 'flex',
  flexFlow: 'column',
};

export const ListingCardTitle = {
  color: 'text',
  fontSize: '1.3rem',
  lineHeight: 1.3,
  fontWeight: 'bold',
  textTransform: 'none',
  fontFamily: 'body',
  padding: '0 !important',
  marginY: 2,
};

export const ListingCardSubtitle = {
  color: 'text',
  fontSize: '0.9rem',
  lineHeight: 1.3,
  fontWeight: '400',
};

export const IconSpecsColumn = {
  textAlign: 'center',
  paddingRight: 2,
};

export const DetailsButton = {
  display: 'flex',
  alignSelf: 'flex-start',
  marginTop: 4,
  width: 'auto',
};
