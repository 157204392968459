/** @jsx jsx */
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';
import {
  DetailsButton as buttonStyles,
  ListingCard as cardStyles,
  ListingCardBody as cardBodyStyles,
  ListingCardImage as imageStyles,
  ListingCardSubtitle as subtitleStyles,
  ListingCardTitle as titleStyles,
} from '../ListingsList/ListingCard/ListingCard.5.styles';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import { jsx } from 'theme-ui';

const RatgeberItem = ({
  featuredImage,
  title,
  subtitle,
  description,
  slug,
  alternativeImage,
  leftButton,
  rightButton,
  rightSlug,
}) => {
  const data = useStaticQuery(graphql`
    {
      images: allImageSharp(
        filter: { fixed: { originalName: { regex: "/^expertentipps/" } } }
      ) {
        nodes {
          fluid(maxWidth: 2000, fit: COVER, quality: 90) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            originalName
          }
        }
      }
    }
  `);
  const ratgeber_image = data.images.nodes.filter(node =>
    node.fixed.originalName.startsWith(alternativeImage)
  );

  if (!featuredImage && ratgeber_image && ratgeber_image[0]) {
    featuredImage = { childImageSharp: ratgeber_image[0] };
  }

  if (!leftButton) {
    leftButton =
      '<span>Mehr erfahren <i class="fal fa-arrow-right"></i></span>';
  }

  return (
    <Card
      as={Link}
      to={`${slug}`}
      // The state is forwarded to the ListingPage to enable paging through the search results.
      text="white"
      sx={{ ...cardStyles, marginBottom: 4 }}
    >
      {featuredImage && featuredImage.childImageSharp ? (
        <ListingImage
          fluid={featuredImage.childImageSharp.fluid}
          alt={title}
          sx={imageStyles}
        />
      ) : (
        <div
          sx={{
            paddingBottom: `${100}%`,
            backgroundColor: 'primary',
            ...imageStyles,
          }}
        />
      )}
      <Card.Body sx={cardBodyStyles}>
        <div sx={{ height: '100%' }}>
          <div sx={{ ...subtitleStyles }}>{subtitle}</div>
          <Card.Title sx={{ ...titleStyles }} as="h2">
            {title}
          </Card.Title>
          <div sx={{ ...subtitleStyles }}>{description}</div>
        </div>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={buttonStyles}
            variant="primary"
            dangerouslySetInnerHTML={{ __html: leftButton }}
          ></Button>
          {rightButton && (
            <Button sx={buttonStyles} to={rightSlug} variant="primary">
              {rightButton}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

function ListingImage({ alt, fluid, className }) {
  return (
    <Card.Img
      variant="top"
      as={Image}
      fluid={fluid}
      alt={alt}
      className={className}
    />
  );
}

export default RatgeberItem;
